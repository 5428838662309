var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"mb-2"},[_c('v-select',{class:[
      'default-input-style',
      _vm.noMargins ? 'mx-0' : _vm.hasItems ? 'ml-4' : 'mx-4' ],attrs:{"outlined":"","hide-details":_vm.horizontal || _vm.hideDetails,"no-data-text":_vm.noDataText,"mandatory":"","clearable":_vm.clearable,"label":_vm.label,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"items":_vm.dividedItems,"item-value":_vm.itemValue,"return-object":_vm.returnObject,"item-text":_vm.itemText,"attach":_vm.attach,"menu-props":{
      contentClass: 'zg-select-border-radius',
      bottom: true,
      offsetY: true,
    },"append-icon":"mdi-chevron-down","clear-icon":"mdi-close","persistent-placeholder":"","dense":""},scopedSlots:_vm._u([(_vm.useCustomSlotSelect)?{key:"selection",fn:function(ref){
    var item = ref.item;
return [_vm._t("customItemSelection",null,{"item":item})]}}:null,(_vm.useCustomSlotItem)?{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._t("customItem",null,{"item":item})]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-layout',{class:[!_vm.hideDetails && 'mb-5', _vm.hasItems && 'mr-3'],attrs:{"shrink":"","align-center":""}},[_vm._t("prepend-label-icon"),_vm._t("label-icon"),(_vm.autosave)?_c('AutosaveIcon',{attrs:{"value":_vm.status}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }