<template>
  <v-layout class="mb-2">
    <v-select
      outlined
      :hide-details="horizontal || hideDetails"
      :class="[
        'default-input-style',
        noMargins ? 'mx-0' : hasItems ? 'ml-4' : 'mx-4',
      ]"
      :no-data-text="noDataText"
      mandatory
      :clearable="clearable"
      :label="label"
      :placeholder="placeholder"
      v-model="selected"
      :disabled="disabled"
      :items="dividedItems"
      :item-value="itemValue"
      :return-object="returnObject"
      :item-text="itemText"
      :attach="attach"
      :menu-props="{
        contentClass: 'zg-select-border-radius',
        bottom: true,
        offsetY: true,
      }"
      append-icon="mdi-chevron-down"
      clear-icon="mdi-close"
      persistent-placeholder
      dense
    >
      <template v-if="useCustomSlotSelect" v-slot:selection="{ item }">
        <slot name="customItemSelection" v-bind:item="item"></slot>
      </template>
      <template v-if="useCustomSlotItem" v-slot:item="{ item }">
        <slot name="customItem" v-bind:item="item"></slot>
      </template>
    </v-select>
    <v-layout
      shrink
      align-center
      :class="[!hideDetails && 'mb-5', hasItems && 'mr-3']"
    >
      <slot name="prepend-label-icon"></slot>
      <slot name="label-icon"></slot>
      <AutosaveIcon v-if="autosave" :value="status"></AutosaveIcon>
    </v-layout>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    value: {},
    items: {
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {},
    horizontal: {
      type: Boolean,
      default: false,
    },
    maximum: {
      default: 240,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      default: "Brak danych",
    },
    itemValue: {
      default: () => {},
    },
    itemText: {
      default: () => {},
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    placeholder: {},
    returnObject: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    },
    useCustomSlotItem: {
      type: Boolean,
      default: false,
    },
    useCustomSlotSelect: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    noMargins: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasIconSlot() {
      return !!this.$slots["prepend-label-icon"];
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    dividedItems() {
      let newData = [];
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        newData.push(element);
        if (index !== this.items.length - 1) {
          newData.push({ divider: true });
        }
      }
      return newData;
    },
    hasItems() {
      return !!this.$slots["label-icon"];
    },
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
  },
  mounted() {
    if (this.autosave) {
      this.startWatching("selected");
    }
  },
};
</script>

<style scoped>
::v-deep .v-divider {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
</style>
